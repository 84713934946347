import "../../../assets/style/Demo.css";

import Topbar from "../../../component/Topbar/Topbar";
import Footer from "../../../component/Footer/Footer";
import { Link } from "react-router-dom";

import arrow from "../../../assets/icon/arrowRight.svg";

import nlp from "../../../assets/photo/NLP.webp";
import fr1 from "../../../assets/photo/frame1.webp";
import fr2 from "../../../assets/photo/frame2.webp";
import fr3 from "../../../assets/photo/frame3.webp";
import fr4 from "../../../assets/photo/frame4.webp";

import aiic1 from "../../../assets/icon/aiic1.svg";
function NLP(params) {

    const listPicture = [{
        src: fr1,
        no: 1
    },
    {
        src: fr2,
        no: 2
    },
    {
        src: fr3,
        no: 3
    },
    {
        src: fr4,
        no: 4
    }];

    const listCapability1 = [{
        tag: "Language",
        title: "Question Answer",
        text: "Enhance your user experience and streamline information retrieval with our advanced Question Answering (QA) solutions.",
        icon: aiic1,
        link: "/qa"
    },
    {
        tag: "Language",
        title: "News",
        text: "Stay up-to-date effortlessly with our advanced News Extraction solutions, ensuring you receive relevant and timely information.",
        icon: aiic1,
        link: "/news"
    },
    {
        tag: "Language",
        title: "Fewshot",
        text: "Isolate individual voices from mixed audio tracks, ideal for editing, transcription, and communication clarity.",
        icon: aiic1,
        link: "/fewshot"
    }];
    

    return(
        <>
            <Topbar/>

            <section className="demo">
                
                <div className="breadcrumbDemo">
                    <Link to={"/"}>Home</Link>
                    <img src={arrow} alt="arrow"></img>
                    <Link to={"/demo"}>Demo</Link>
                    <img src={arrow} alt="arrow"></img>
                    <p style={{color: '#37C871'}}>NLP</p>
                </div>

                <div className="intermezzoNLP">
                    <div className="topDemo">
                        <div className="dflex width100">
                            <img src={nlp} className="width100" alt="women"></img>
                        </div>
                        <div>
                            <h1 className="fw800">Harness the Power of Advanced NLP</h1>
                            <p>Unlock the full potential of your data and interactions with our cutting-edge Natural Language Processing solutions. Leverage advanced NLP for precise Question Answering, News extraction, and Fewshot learning with minimal data.</p>
                        </div>
                        
                    </div>

                    <div className='listPictureDemo goneMobile'>
                        {listPicture.map((item, index) => (
                            <div key={index} style={{flex: '1'}}>
                                <img src={item.src} className={index === 0 ? 'leftBorder width100' : index === 3 ? 'rightBorder width100' : 'width100'} alt="listdemo"/>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="sectionDemo">
                    <div className="listAIDemo goneMobile">
                        {listCapability1.map((item, index) => (
                            <div key={index} className="cardAIDemo"> 
                                <div>
                                    <div className="tagCardDemo">{item.tag}</div>
                                    <p className="titleCardDemo">{item.title}</p>
                                    <p className="textCardDemo">{item.text}</p>
                                </div>

                                <div className="mb24">
                                    <hr/>
                                    <div className="bottomCardDemo">
                                        <img src={item.icon} alt="icon"></img>
                                        <Link to={item.link} className="buttonSubmit" onClick={() => {document.body.scrollIntoView({behavior: "smooth"});}} >Try Demo</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="listAIDemoMobile">
                        {listCapability1.map((item, index) => (
                            <div className="cardDemoMobile">
                                <div className="cardTopMobile">
                                    <div>
                                        <img src={item.icon} alt="what?"/>
                                    </div>
                                    <p>{item.title}</p>
                                </div>
                                
                                <Link to={item.link} className="buttonGrayBorder" style={{textAlign: 'center', placeSelf: 'center'}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"});}} >Try Demo</Link>

                            </div>
                        ))}
                    </div>

                </div>

            </section>

            <Footer/>
        </>
    )
}

export default NLP