import "../../../assets/style/Capability.css";

import Topbar from "../../../component/Topbar/Topbar"
import Footer from "../../../component/Footer/Footer"

import arrow from "../../../assets/icon/arrowRight.svg";
import loading from "../../../assets/icon/loading.svg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import useWebSocket from 'react-use-websocket';


function Fewshot(params) {
    const [context, setContext] = useState("")
    const [target, setTarget] = useState("")
    const [status, setStatus] = useState("");
    const [answer, setAnswer] = useState("");
    const [maxLength, setMaxLength] = useState("3");
    const [userId, setUserId] = useState(null);
    const [spamClick, setSpamClick] = useState(false);
    const [errorContext, setErrorContext] = useState(false);
    const [errorTarget, setErrorTarget] = useState(false);
    const [contentErrorTarget, setContentErrorTarget] = useState("Please input your Target :))")

    // const {sendMessage,sendJsonMessage,lastMessage,lastJsonMessage,readyState,getWebSocket} = useWebSocket(process.env.REACT_APP_WS);

    const {lastJsonMessage} = useWebSocket(process.env.REACT_APP_WS);

    const submitFewshot = () => {

        if(context === "" || target === "" || maxLength < 3 || maxLength > 20){

            if(context === "") {
                // setAnswer("Input your Context please :))")

                const link = document.createElement("a");
                link.href = "#errorContext";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorContext(true);
                setTimeout(() => {
                    setErrorContext(false)
                }, 4000);
            }
            if(target === "") {
                // setAnswer("Input your Target please :))")
                setContentErrorTarget("Please input your Target :))")

                const link = document.createElement("a");
                link.href = "#errorTarget";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorTarget(true);
                setTimeout(() => {
                    setErrorTarget(false)
                }, 4000);
            }
            if(maxLength < 3) {
                setContentErrorTarget("Please input enter max length greater than 3 :))")

                const link = document.createElement("a");
                link.href = "#errorTarget";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorTarget(true);
                setTimeout(() => {
                    setErrorTarget(false)
                }, 4000);
            }
            if(maxLength > 20) {
                setContentErrorTarget("Please input enter max length less than 20 :))")

                const link = document.createElement("a");
                link.href = "#errorTarget";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorTarget(true);
                setTimeout(() => {
                    setErrorTarget(false)
                }, 4000);
            }
        } else if (context === `ask: 'i drink use to' 
answer: 'glass' 

ask: 'i eat use to' 
answer: 'spoons'` && target === `ask: 'i shot use to'
answer:`){
            setAnswer(`
ask: 'i drink use to' 
answer: 'glass' 

ask: 'i eat use to' 
answer:'spoons' 

ask: 'i shot use to' 
answer:'shotgun'`)
        } else {
            setStatus('Processing Text')
            setSpamClick(true)

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
            const urlencoded = new URLSearchParams();
            urlencoded.append("text", target);
            urlencoded.append("userId", userId);
            urlencoded.append("context", `${context}\n`);
            urlencoded.append("maxLength", maxLength);
    
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };
    
            fetch(`${process.env.REACT_APP_BE}fewshoot/call`, requestOptions)
            .then((response) => {
                if(response.ok) return response.json()
                else{
                    alert(`Oops Sorry, server is busy... please Reload :) `);
                    setStatus('');
                    setSpamClick(false);
                }
            })
            .then((result) => {
                // console.log(result); 
                if(result !== undefined){
                    setStatus(result.status);
                }
                
            })
            .catch((error) => {
                // console.error(error)
                alert(`Oops Sorry, server is busy... please Reload :) `);
                setStatus('');
                setSpamClick(false);
            });
        }
    }

    useEffect(() => {
        // console.log(lastJsonMessage);

        if(lastJsonMessage !== null && userId === null){
            setUserId(lastJsonMessage.userId)
        }

        if(status === 'Processing Text'){
            setAnswer(`${lastJsonMessage.result}`)
            setStatus('');
            setSpamClick(false)
        }

    // eslint-disable-next-line 
    },[lastJsonMessage])


    return(
        <>
            <Topbar/>

            <section className="capability">

                <div className="breadcrumbDemo">
                    <Link to={"/"}>Home</Link>
                    <img src={arrow} alt="arrow"></img>
                    <Link to={"/demo"}>Demo</Link>
                    <img src={arrow} alt="arrow"></img>
                    <Link to={"/nlp"}>NLP</Link>
                    <img src={arrow} alt="arrow"></img>
                    <p style={{color: '#37C871'}}>Fewshot</p>
                </div>

                <div className="contentCapability" id="errorContext">
                    <p className="titleCapability">See How Fewshot NLP Transforms Businesses</p>
                    <div className="kotakCapability"></div> 
                    <p className="textCapability">Experience the power of our Fewshot NLP through practical, real-world applications. Our demo showcases how this advanced feature can revolutionize various aspects of your business operations.</p>
                </div>

                <div className="mainCapability">

                    <div className="inputCapability">
                        <div className={errorContext ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"} >  
                            <label>Input Context</label>
                            <textarea placeholder="Ask your question." value={context} onChange={(event) => setContext(event.target.value)}/>

                            <p className="noMargin" id="errorTarget"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorContext ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    Please input your Context :))
                            </p>
                        </div>
                        <div className="textAreaCapability">   
                            <label>Copy Example</label>
<p className="textExampleCapability"
onClick={() => setContext(
`ask: 'i drink use to' 
answer: 'glass' 

ask: 'i eat use to' 
answer: 'spoons'`
)}>ask:  'i drink use to'<br/> answer: 'glass'<br/><br/> ask:  'i eat use to'<br/> answer: 'spoons' </p>
                        </div>
                    </div>

                    <div className="inputCapability">
                        <div className={errorTarget ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"}>
                            <label>Input Target</label>
                            <textarea placeholder="Ask your question." value={target} onChange={(event) => setTarget(event.target.value)}/>
                            <label>Max Length</label>
                            <input type="number" value={maxLength} min={3} onChange={(event) => setMaxLength(event.target.value)}></input>

                            <p className="noMargin"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorTarget ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    {contentErrorTarget}
                            </p>
                        </div>
                        <div className="textAreaCapability">   
                            <label>Copy Example</label>
<p className="textExampleCapability"
onClick={() => setTarget(
`ask: 'i shot use to'
answer:`)}>ask:  'i shot use to'<br/>answer: </p>
                        </div>
                    </div>

                    <div className="submitCapability">
                        <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => submitFewshot()} disabled={spamClick}>Submit</button>
                        {spamClick === true && (
                            <img src={loading} width={60} alt="up"></img>
                        )}
                    </div>

                    <div>
                        <div className="textAreaCapability">                    
                            <label>Result</label>
                            <div className="resultCapability">
                            {status === 'Processing Text' ? (
                                    <>
                                        <img src={loading} style={{width: '100%'}} alt="up"></img>
                                    </>
                                ) : (<>
                                    <p>
                                        {
                                            answer
                                        }
                                    </p>
                                </>)}

                            </div>
                        </div>
                    </div>

                </div>



            </section>

            <Footer/>
        </>
    )
}

export default Fewshot