import "../../../assets/style/Capability.css";

import Topbar from "../../../component/Topbar/Topbar"
import Footer from "../../../component/Footer/Footer"

import arrow from "../../../assets/icon/arrowRight.svg";
import loading from "../../../assets/icon/loading.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import useWebSocket from 'react-use-websocket';


function News(params) {
    const [news, setNews] = useState("");
    const [status, setStatus] = useState("");
    const [answer, setAnswer] = useState("");
    const [userId, setUserId] = useState(null);
    const [maxLength, setMaxLength] = useState("10");
    const [spamClick, setSpamClick] = useState(false);
    const [errorNews, setErrorNews] = useState(false);
    const [contentErrorNews, setContentErrorNews] = useState("Please input your news :))")

    // const {sendMessage,sendJsonMessage,lastMessage,lastJsonMessage,readyState,getWebSocket} = useWebSocket(process.env.REACT_APP_WS);

    const {lastJsonMessage} = useWebSocket(process.env.REACT_APP_WS);

    const submitNews = () => {

        if(news === "" || news.length < 20 || maxLength < 10 || maxLength > 20){
            if(news === "" || news.length < 20 ) {
                // setAnswer("Please input your news")
                setContentErrorNews("Please input your news :))")

                const link = document.createElement("a");
                link.href = "#errorNews";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorNews(true);
                setTimeout(() => {
                    setErrorNews(false)
                }, 4000);
            }
            if(maxLength < 10) {
                setContentErrorNews("Enter max length greater than 10 :))")
            
                const link = document.createElement("a");
                link.href = "#errorNews";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorNews(true);
                setTimeout(() => {
                    setErrorNews(false)
                }, 4000);
            }
            if(maxLength > 20) {
                setContentErrorNews("Enter max length less than 20 :))")
            
                const link = document.createElement("a");
                link.href = "#errorNews";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorNews(true);
                setTimeout(() => {
                    setErrorNews(false)
                }, 4000);
            }
        }  else if (news === "However, after Russian figure skater Kamila Valieva had a doping test come back positive for the performance-enhancing substance trimetazidine, no medals were awarded until the Court of Arbitration for Sport (CAS) handed down a decision to disqualify Valieva of “all competitive results” achieved since her positive test."){
            setAnswer("However, after Russian figure skater Kamila Valieva had a doping test come back positive for the performance-enhancing substance trimetazidine, no medals were awarded until the Court of Arbitration for Sport (CAS) handed down a decision to disqualify Valieva of “all competitive results” achieved since her positive test.")
        }  else {
            setStatus('Processing Text')
            setSpamClick(true)
            const urlencoded = new URLSearchParams();
            urlencoded.append("text", news);
            urlencoded.append("userId", userId);
            urlencoded.append("maxLength", maxLength);
            urlencoded.append("context", "news");
    
            const requestOptions = {
                method: "POST",
                body: urlencoded,
                redirect: "follow"
            };
    
            fetch(`${process.env.REACT_APP_BE}news/infer`, requestOptions)
                .then((response) => {
                    if(response.ok) return response.json()
                    else{
                        alert(`Oops Sorry, server is busy... please Reload :) `);
                        setStatus('');
                        setSpamClick(false);
                    }
                })
                .then((result) => {                    
                    // console.log(result); 
                    if(result !== undefined){
                        setStatus(result.status);
                    }
                    })
                .catch((error) => {
                    console.error(error)
                    alert(`Oops Sorry, server is busy... please Reload :) `);
                    setStatus('');
                    setSpamClick(false);
                });
        }
    }



    useEffect(() => {
        if(lastJsonMessage !== null && userId === null){
            setUserId(lastJsonMessage.userId)
        }

        if(status === 'Processing Text'){
            setAnswer(lastJsonMessage.result)
            setStatus('');
            setSpamClick(false)
        }

    // eslint-disable-next-line 
    },[lastJsonMessage])


    return(
        <>
            <Topbar/>

            <section className="capability">

                <div className="breadcrumbDemo">
                    <Link to={"/"}>Home</Link>
                    <img src={arrow} alt="arrow"></img>
                    <Link to={"/demo"}>Demo</Link>
                    <img src={arrow} alt="arrow"></img>
                    <Link to={"/nlp"}>NLP</Link>
                    <img src={arrow} alt="arrow"></img>
                    <p style={{color: '#37C871'}}>News Generation</p>
                </div>

                <div className="contentCapability" id="errorNews">
                    <p className="titleCapability">See How News Generation NLP Transforms Businesses</p>
                    <div className="kotakCapability"></div>
                    <p className="textCapability">Experience the power of our News Generation NLP through practical, real-world applications. Our demo showcases how this advanced feature can revolutionize various aspects of your business operations.</p>
                </div>

                <div className="mainCapability">

                    <div className="inputCapability">
                        <div className={errorNews ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"} >                  
                            <label>News</label>
                            <textarea placeholder="Input your news" value={news} onChange={(event) => {setNews(event.target.value)}}/>
                            <label>Max Length</label>
                            <input type="number" value={maxLength} min={3} onChange={(event) => setMaxLength(event.target.value)}></input>

                            <p className="noMargin"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorNews ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    {contentErrorNews}
                            </p>
                        </div>
                        <div className="textAreaCapability">   
                            <label>Copy Example</label>
                            <p className="textExampleCapability" onClick={() => {setNews("However, after Russian figure skater Kamila Valieva had a doping test come back positive for the performance-enhancing substance trimetazidine, no medals were awarded until the Court of Arbitration for Sport (CAS) handed down a decision to disqualify Valieva of “all competitive results” achieved since her positive test.")}}>However, after Russian figure skater Kamila Valieva had a doping test come back positive for the performance-enhancing substance trimetazidine, no medals were awarded until the Court of Arbitration for Sport (CAS) handed down a decision to disqualify Valieva of “all competitive results” achieved since her positive test.</p>
                            <a href="https://edition.cnn.com/2024/08/07/sport/usa-figure-skating-gold-medals-olympics-spt-intl/index.html" target="_blank" rel='noreferrer'>https://edition.cnn.com/2024/08/07/sport/usa-figure-skating-gold-medals-olympics-spt-intl/index.html</a>
                        </div>
                    </div>

                    <div className="submitCapability">
                        <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => submitNews()} disabled={spamClick}>Submit</button>
                        {spamClick === true && (
                            <img src={loading} width={60} alt="up"></img>
                        )}
                    </div>

                    <div>
                        <div className="textAreaCapability">                    
                            <label>Result</label>
                            <div className="resultCapability">
                                {status === 'Processing Text' ? (
                                    <>
                                        <img src={loading} style={{width: '100%'}} alt="up"></img>
                                    </>
                                ) : (<>
                                    <p>
                                        {answer}
                                    </p>
                                </>)}

                            </div>
                        </div>
                    </div>

                </div>





            </section>

            <Footer/>
        </>
    )
}

export default News