import "../../../assets/style/Capability.css";

import Topbar from "../../../component/Topbar/Topbar"
import Footer from "../../../component/Footer/Footer"

import arrow from "../../../assets/icon/arrowRight.svg";
import upload from "../../../assets/icon/upload.svg";
import x from "../../../assets/icon/x.svg";

import clip from "../../../assets/video/video.mp4";
import exampleMotion from "../../../assets/video/openWithBlenderAndImportFbx.fbx";
import loading from "../../../assets/icon/loading.svg";

import Dropzone from 'react-dropzone'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useWebSocket from 'react-use-websocket';


function Motion3D(params) {
    const [fileTarget, setFileTarget] = useState([])
    const [userId, setUserId] = useState(null);
    const [status, setStatus] = useState("");
    const [exampleTarget, setExampleTarget] = useState(false);
    const [spamClick, setSpamClick] = useState(false);
    const [errorTarget, setErrorTarget] = useState(false);

    // const {sendMessage,sendJsonMessage,lastMessage,lastJsonMessage,readyState,getWebSocket} = useWebSocket(process.env.REACT_APP_WS);

    const {lastJsonMessage} = useWebSocket(process.env.REACT_APP_WS);


    function formatBytes(bytes) {
        if (bytes < 1024) return bytes + ' Bytes';
        else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(2) + ' KB';
        else if (bytes < 1024 * 1024 * 1024) return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
        else return (bytes / (1024 * 1024 * 1024)).toFixed(6) + ' GB';
    }

    const submit3D = () => {

        if(fileTarget.length === 0 || exampleTarget){

            if(fileTarget.length === 0){
                const link = document.createElement("a");
                link.href = "#errorTarget";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorTarget(true);
                setTimeout(() => {
                    setErrorTarget(false)
                }, 4000);
            }

            if(exampleTarget) {
                const link = document.createElement("a");
                link.href = exampleMotion;
                link.setAttribute('download', 'openWithBlenderAndImportFbx.fbx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

        } else {
            setSpamClick(true);
            setStatus("Loading");

            const formdata = new FormData();
            formdata.append("userId", userId);
            formdata.append("file", fileTarget[0]);

            const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow"
            };

            fetch(`${process.env.REACT_APP_BE}3d/infer`, requestOptions)
            .then((response) => {
                if(response.ok) return response.json()
                else{
                    alert(`Oops Sorry, server is busy... please Reload :) `);
                    setStatus('');
                    setSpamClick(false);
                }
            })
            .then((result) => {
                // console.log(result)
                if(result.success === false){
                    alert(`Oops Sorry, server is busy... please Reload :) `);
                    setStatus('');
                    setSpamClick(false);
                }
            })
            .catch((error) => {
                // console.error(error)
                alert(`Oops Sorry, server is busy... please Reload :) `);
                setStatus('');
                setSpamClick(false);
            });

        }
        
    }

    useEffect(() => {
        // console.log(lastJsonMessage)
        if(lastJsonMessage !== null && userId === null){
            setUserId(lastJsonMessage.userId)
        }

        if(status === 'Loading'){
            const link = document.createElement("a");
            link.href = lastJsonMessage.result.file;
            link.download = "result.fbx"; // Nama file yang diinginkan
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(lastJsonMessage.result.file);
            setStatus('');
            setSpamClick(false);
        }

    // eslint-disable-next-line 
    },[lastJsonMessage])

    return(
        <>
            <Topbar/>

            <section className="capability">

                <div className="breadcrumbDemo">
                    <Link to={"/"}>Home</Link>
                    <img src={arrow} alt="arrow"></img>
                    <Link to={"/demo"}>Demo</Link>
                    <img src={arrow} alt="arrow"></img>
                    <p style={{color: '#37C871'}}>3D Motion</p>
                </div>

                <div className="contentCapability" id="errorTarget">
                    <p className="titleCapability">See How Custom 3D Motion Transforms Businesses</p>
                    <div className="kotakCapability"></div>
                    <p className="textCapability">Experience the power of our Custom 3D Motion through practical, real-world applications. Our demo showcases how this advanced feature can revolutionize various aspects of your business operations.</p>
                </div>

                <div className="mainCapability">

                    <div className="inputCapability">
                        <div className={errorTarget ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"}>                  
                            <label>Media Upload</label>
                            

                            <Dropzone onDrop= {acceptedFiles => {
                                setFileTarget( acceptedFiles.map(file => Object.assign( file, {preview: URL.createObjectURL(file)})) )
                            }} accept={{'video/mp4': [], 'video/x-msvideo': []}} maxSize={10000000}>
                                {({getRootProps, getInputProps}) => (
                                    <section onClick={() => {setFileTarget([]); setExampleTarget(false)}} style={{cursor: 'pointer'}}>
                                        <div className="uploadCapability" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <img src={upload} alt="up"/>
                                            <p>Drag your file(s) to start uploading</p>
                                            <div className="orUpload">
                                                <div className="garisPutih"></div>
                                                <p>OR</p>
                                                <div className="garisPutih"></div>
                                            </div>
                                            <button className="buttonGreenFill">Browser file</button>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>

                            <div className="upCapability">
                                <p className="informationUploadCapability">Supported formats: MP4, AVI</p>
                                <p className="informationUploadCapability">Maximum size: 10MB</p>
                            </div>

                            {fileTarget.map((item, index) => (
                                <div key={index} className="itemUploadCapability">
                                    <div className="itemInfoCapability">
                                        <div className="itemDetailInfoCapability">
                                            <div className="itemKeteranganCapability">
                                                <p className="itemNameCapability">{item.name}</p>
                                                <p className="itemSizeCapability">{formatBytes(item.size)}</p>
                                            </div>
                                        </div>
                                        <img src={x} onClick={() => {setFileTarget([]); setExampleTarget(false)}} style={{cursor: 'pointer'}} alt="up"/>
                                    </div>
                                </div>
                            ))}

                            <p className="noMargin"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorTarget ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    Please input your video :))
                            </p>

                        </div>
                        <div className="textAreaCapability">   
                            <label>Copy Example</label>
                            <video src={clip} width={260} controls/>
                            <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => {setFileTarget([{preview: clip, name: 'example.mp4', size: 0}]); setExampleTarget(true)}}>Copy Example</button>
                        </div>
                    </div>

                    <div className="submitCapability">
                        <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => submit3D()} disabled={spamClick}>Submit</button>
                        {spamClick === true && (
                            <img src={loading} width={60} alt="up"></img>
                        )}
                    </div>
                    

                    {/* <div>
                        <div className="textAreaCapability">                    
                            <label>Result</label>
                            <div className="resultCapability">
                                <p></p>
                            </div>
                        </div>
                    </div> */}

                </div>



            </section>

            <Footer/>

        </>
    )
}

export default Motion3D