import './App.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Homepage from "./page/Homepage/Homepage.js";
import ContactUs from "./page/ContactUs/ContactUs.js";
import AboutUs from './page/AboutUs/AboutUs.js';
import Demo from './page/Demo/Demo.js';
import SoftwareDev from './page/SoftwareDevelopment/SoftwareDevelopment.js';
import BigData from './page/BigData/BigData.js';
import DataAnalytics from './page/DataAnalytics/DataAnalytics.js';
import Term from './page/TermPolicy/Term.js';
import Privacy from './page/TermPolicy/Privacy.js';
import NLP from './page/Demo/NLP/NLP.js';
import QA from './page/Demo/Capability/QA.js';
import News from './page/Demo/Capability/News.js';
import Fewshot from './page/Demo/Capability/Fewshot.js';
import VoiceCloning from './page/Demo/Capability/VoiceCloning.js';
import VoiceSeparation from './page/Demo/Capability/VoiceSeparation.js';
import CustomImage from './page/Demo/Capability/CustomImage.js';
import ImageSimilarity from './page/Demo/Capability/ImageSimilarity.js';
import OCR from './page/Demo/Capability/OCR.js';
import Motion3D from './page/Demo/Capability/Motion3D.js';


function App() {

  const router = createBrowserRouter([
    { path: "/", element: <Homepage/> },
    { path: "/about-us", element: <AboutUs/> },
    { path: "/contact-us", element: <ContactUs/> },

    { path: '/demo', element: <Demo/> },
    { path: '/nlp', element: <NLP/> },
    { path: '/qa', element: <QA/> },
    { path: '/news', element: <News/> },
    { path: '/fewshot', element: <Fewshot/> },
    { path: '/voice-cloning', element: <VoiceCloning/> },
    { path: '/voice-separation', element: <VoiceSeparation/> },
    { path: '/custom-image-classification', element: <CustomImage/> },
    { path: '/custom-image-similarity', element: <ImageSimilarity/> },
    { path: '/ocr', element: <OCR/> },
    { path: '/3d-motion', element: <Motion3D/> },

    { path: '/software', element: <SoftwareDev/> },
    { path: '/bigdata', element: <BigData/> },
    { path: '/data', element: <DataAnalytics/> },

    { path: "/term-of-service", element: <Term/> },
    { path: "/privacy-policy", element: <Privacy/> },
    { path: "*", element: <Homepage/> }
  ]);
  

  return (
    <div className='page'>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
