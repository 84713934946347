import '../../assets/style/Footer.css';

import Modal from 'react-modal';

import phone from '../../assets/icon/phone.svg';
import mail from '../../assets/icon/mail.svg';
import our1 from "../../assets/icon/our1.svg";
import our2 from "../../assets/icon/our2.svg";
import our3 from "../../assets/icon/our3.svg";
import our4 from "../../assets/icon/our4.svg";
import { Link } from 'react-router-dom';
import { useState } from 'react';

function Footer() {
    const [open, setOpen] = useState(false);
    const [done, setDone] = useState(false);
    const [appear, setAppear] = useState(false);

    const listOur = [{
        icon: our1,
        title: "AI Solutions",
        text: 'Use human intelligence to create artificial intelligence that can solve many problems for example with CV or NLP.',
        link: '/demo'
    },
    {
        icon: our2,
        title: "Software Development",
        text: 'Create and develop applications to support your business with us. Web apps and mobile apps will be very helpful.',
        link: '/software'
    },
    {
        icon: our3,
        title: "Big Data Intelligence",
        text: 'Nowadays big data is something important for companies, intelligence to  manage it is a must. So we are here to bring a solution for that.',
        link: '/bigdata'
    },
    {
        icon: our4,
        title: "Data Analytics",
        text: 'Increase revenue, operational efficiency, and optimize marketing  programs and customer service efforts by analyzing existing data so that  it becomes useful information.',
        link: '/data',
    }];

    function copyToClipboard(text) {
        setDone(true);
        setAppear(true);
        setTimeout(() => {
            setAppear(false)
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text)
            } else {
                const input = document.createElement('textarea')
                input.value = text
                document.body.appendChild(input)
                input.select()
                document.execCommand('copy')
                document.body.removeChild(input)
            }
        }, 100)
        setTimeout(()=>{
            setDone(false)
            setAppear(true)
        },3000)
        setTimeout(() => {
            setAppear(false)
        }, 3200)


    }

    const balikAtas = () => {
        document.body.scrollIntoView({
            behavior: "smooth",
        });
    };

    return(
        <>
            <footer className='footer' id='Footer'>
                <div className='topFooter'>
                    <p className="textTopFooter">We still have many other services that <br/> we will introduce to you.</p>
                    <div>
                        <button className="button1Footer" onClick={() => setOpen(!open)}>See our other services</button>
                    </div>
                </div>

                <hr className='lineHorizontalFooter'/>

                <div>
                    <p className="textBottomFooter">Ruko Simprug Plaza Blok B2 Nomor 8 <br/> Cikarang Baru, Jababeka, Kabupaten Bekasi.</p>
                    <div className='listContactFooter'>
                        <button className="button2Footer" onClick={() => {window.open('https://wa.me/6281703090603', '_blank')}}>
                            <img src={phone} alt="phone"></img>
                            <p className="noMargin">62 817 0309 0603</p>
                        </button>
                        <button className="button2Footer" onClick={() => {copyToClipboard("infidea@infidea.id")}}>
                            <img src={mail} alt="mail"></img>
                            <p className="noMargin" style={{opacity: appear ? "0" : "1", transition: "all .2s", visibility: appear ? "hidden" : "visible"}}>{done?"it's been copied":"infidea@infidea.id"}</p>
                        </button>
                    </div>
                </div>
            </footer>
            <footer className='copyrightFooter'>
                <p>Copyright © 2024 PT Rekayasa Teknologi Cerdas</p>
                <p>|</p>
                <Link to={'/privacy-policy'}  onClick={() => balikAtas() }>Privacy Policy</Link>
                <p>|</p>
                <Link to={'/term-of-service'}  onClick={() => balikAtas() }>Term of Service</Link>
            </footer>
            <Modal isOpen={open} onRequestClose={() => setOpen(!open)} className="ModalFooter" overlayClassName="OverlayFooter">
                {listOur.map((item, index) => (
                    <Link key={index} className='itemOurFooter' to={item.link} onClick={() => balikAtas() }>
                        <img src={item.icon} alt='icon'/> 
                        <div className='ketOurFooter'>
                            <p className='titleOurFooter'>{item.title}</p>
                            <p className='textOurFooter'>{item.text}</p>
                        </div>
                    </Link>
                ))}
            </Modal>
        </>
    );
};

export default Footer;