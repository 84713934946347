import "../../assets/style/Demo.css";

import Topbar from "../../component/Topbar/Topbar";
import Footer from "../../component/Footer/Footer";
import { Link } from "react-router-dom";

import arrow from "../../assets/icon/arrowRight.svg";

import women from "../../assets/photo/demoWomen.webp";
import fr1 from "../../assets/photo/frame1.webp";
import fr2 from "../../assets/photo/frame2.webp";
import fr3 from "../../assets/photo/frame3.webp";
import fr4 from "../../assets/photo/frame4.webp";

import aiic1 from "../../assets/icon/aiic1.svg";
import aiic2 from "../../assets/icon/aiic2.svg";
import aiic3 from "../../assets/icon/aiic3.svg";
import aiic4 from "../../assets/icon/aiic4.svg";
import aiic5 from "../../assets/icon/aiic5.svg";
import aiic6 from "../../assets/icon/aiic6.svg";
import aiic7 from "../../assets/icon/aiic7.svg";
function Demo(params) {

    const listPicture = [{
        src: fr1,
        no: 1
    },
    {
        src: fr2,
        no: 2
    },
    {
        src: fr3,
        no: 3
    },
    {
        src: fr4,
        no: 4
    }];

    const listCapability1 = [{
        tag: "Language",
        title: "Natural Language Processing",
        text: "Leverage advanced NLP for precise Question Answering, News extraction, and Fewshot learning with minimal data.",
        icon: aiic1,
        link: "/nlp"
    },
    {
        tag: "Voice",
        title: "Voice Cloning",
        text: "Create realistic voice replicas for virtual assistants, audiobooks, and more with our Voice Cloning technology.",
        icon: aiic2,
        link: "/voice-cloning"
    },
    {
        tag: "Voice",
        title: "Voice Separation",
        text: "Isolate individual voices from mixed audio tracks, ideal for editing, transcription, and communication clarity.",
        icon: aiic3,
        link: "/voice-separation"
    }];

    const listCapability2 = [
    {
        tag: "Image",
        title: "Custom Image Classification",
        text: "Efficiently categorize and identify objects in images with our customizable image recognition solutions.",
        icon: aiic4,
        link: "/custom-image-classification"
    },
    {
        tag: "Image",
        title: "Image Similarity",
        text: "Enhance search engines and photo libraries with fast, accurate visually similar image detection.",
        icon: aiic5,
        link: "/custom-image-similarity"
    },
    {
        tag: "Image",
        title: "Optical Character Recognition",
        text: "Convert printed text into digital format with our OCR feature. Seamlessly transform e-statements into editable and searchable data, enhancing workflow efficiency and data accessibility.",
        icon: aiic6,
        link: "/ocr"
    }];

    const listCapability3 = [
    {
        tag: "Design",
        title: "3D",
        text: "Create rigging 3D models for design, gaming, and virtual reality applications.",
        icon: aiic7,
        link: "/3d-motion"
    }];

    const listCapability = [
        {
            tag: "Language",
            title: "Natural Language Processing",
            text: "Leverage advanced NLP for precise Question Answering, News extraction, and Fewshot learning with minimal data.",
            icon: aiic1,
            link: "/nlp"
        },
        {
            tag: "Voice",
            title: "Voice Cloning",
            text: "Create realistic voice replicas for virtual assistants, audiobooks, and more with our Voice Cloning technology.",
            icon: aiic2,
            link: "/voice-cloning"
        },
        {
            tag: "Voice",
            title: "Voice Separation",
            text: "Isolate individual voices from mixed audio tracks, ideal for editing, transcription, and communication clarity.",
            icon: aiic3,
            link: "/voice-separation"
        },
        {
            tag: "Image",
            title: "Custom Image Classification",
            text: "Efficiently categorize and identify objects in images with our customizable image recognition solutions.",
            icon: aiic4,
            link: "/custom-image-classification"
        },
        {
            tag: "Image",
            title: "Image Similarity",
            text: "Enhance search engines and photo libraries with fast, accurate visually similar image detection.",
            icon: aiic5,
            link: "/custom-image-similarity"
        },
        {
            tag: "Image",
            title: "Optical Character Recognition",
            text: "Convert printed text into digital format with our OCR feature. Seamlessly transform e-statements into editable and searchable data, enhancing workflow efficiency and data accessibility.",
            icon: aiic6,
            link: "/ocr"
        },
        {
            tag: "Design",
            title: "3D",
            text: "Create motion 3D models for design, gaming, and virtual reality applications.",
            icon: aiic7,
            link: "/3d-motion"
        }
    ]
    

    return(
        <>
            <Topbar/>

            <section className="demo">
                
                <div className="breadcrumbDemo">
                    <Link to={"/"}>Home</Link>
                        <img src={arrow} alt="arrow"></img>
                    <p style={{color: '#37C871'}}>Demo</p>
                </div>

                <div className="intermezzoDemo">
                    <div className="topDemo">
                        <div className="dflex">
                            <img src={women} className="width100" alt="women"></img>
                        </div>
                        <div>
                            <h1 className="fw800">Experience Our AI Features First-Hand</h1>
                            <p>Discover the power and potential of our cutting-edge solutions. With our demo, you can explore our features and see how they can transform your business.</p>
                        </div>
                        
                    </div>

                    <div className='listPictureDemo goneMobile'>
                        {listPicture.map((item, index) => (
                            <div key={index} style={{flex: '1'}}>
                                <img src={item.src} className={index === 0 ? 'leftBorder width100' : index === 3 ? 'rightBorder width100' : 'width100'} alt="listdemo"/>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="sectionDemo">
                    <div className="listAIDemo goneMobile">
                        {listCapability1.map((item, index) => (
                            <div key={index} className="cardAIDemo"> 
                                <div>
                                    <div className="tagCardDemo">{item.tag}</div>
                                    <p className="titleCardDemo">{item.title}</p>
                                    <p className="textCardDemo">{item.text}</p>
                                </div>

                                <div className="mb24">
                                    <hr/>
                                    <div className="bottomCardDemo">
                                        <img src={item.icon} alt="icon"></img>
                                        <Link to={item.link} className="buttonSubmit" onClick={() => {document.body.scrollIntoView({behavior: "smooth"});}} >Try Demo</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="listAIDemo goneMobile">
                        {listCapability2.map((item, index) => (
                            <div key={index} className="cardAIDemo"> 
                                <div>
                                    <div className="tagCardDemo">{item.tag}</div>
                                    <p className="titleCardDemo">{item.title}</p>
                                    <p className="textCardDemo">{item.text}</p>
                                </div>

                                <div className="mb24">
                                    <hr/>
                                    <div className="bottomCardDemo">
                                        <img src={item.icon}  alt="icon"></img>
                                        <Link to={item.link} className="buttonSubmit" onClick={() => {document.body.scrollIntoView({behavior: "smooth"});}} >Try Demo</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="listAIDemo goneMobile">
                        {listCapability3.map((item, index) => (
                            <div key={index} className="cardAIDemo"> 
                                <div>
                                    <div className="tagCardDemo">{item.tag}</div>
                                    <p className="titleCardDemo">{item.title}</p>
                                    <p className="textCardDemo">{item.text}</p>
                                </div>

                                <div className="mb24">
                                    <hr/>
                                    <div className="bottomCardDemo">
                                        <img src={item.icon} alt="icon"></img>
                                        <Link to={item.link} className="buttonSubmit" onClick={() => {document.body.scrollIntoView({behavior: "smooth"});}} >Try Demo</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="listAIDemoMobile">
                        {listCapability.map((item, index) => (
                            <div className="cardDemoMobile">
                                <div className="cardTopMobile">
                                    <div>
                                        <img src={item.icon} alt="what?"/>
                                    </div>
                                    <p>{item.title}</p>
                                </div>
                                
                                <Link to={item.link} className="buttonGrayBorder" style={{textAlign: 'center', placeSelf: 'center'}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"});}} >Try Demo</Link>

                            </div>
                        ))}
                    </div>
                </div>

            </section>

            <Footer/>
        </>
    )
}

export default Demo