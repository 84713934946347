import "../../../assets/style/Capability.css";

import Topbar from "../../../component/Topbar/Topbar"
import Footer from "../../../component/Footer/Footer"

import arrow from "../../../assets/icon/arrowRight.svg";
import loading from "../../../assets/icon/loading.svg";
import upload from "../../../assets/icon/upload.svg";
import mp3 from "../../../assets/icon/mp3.svg";
import x from "../../../assets/icon/x.svg";

import separation from "../../../assets/audio/separation.wav"
import speakerA from "../../../assets/audio/speakerA.wav"
import speakerB from "../../../assets/audio/speakerB.wav"

import Dropzone from 'react-dropzone'
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import useWebSocket from 'react-use-websocket';


function VoiceSeparation(params) {
    const [fileTarget, setFileTarget] = useState([])
    const [exampleTarget, setExampleTarget] = useState(false)
    const [status, setStatus] = useState("");
    const [userId, setUserId] = useState(null);
    const [answer, setAnswer] = useState([]);
    const [spamClick, setSpamClick] = useState(false);
    const [errorTarget, setErrorTarget] = useState(false);

    // const {sendMessage,sendJsonMessage,lastMessage,lastJsonMessage,readyState,getWebSocket} = useWebSocket(process.env.REACT_APP_WS);

    const {lastJsonMessage} = useWebSocket(process.env.REACT_APP_WS);

    function formatBytes(bytes) {
        if (bytes < 1024) return bytes + ' Bytes';
        else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(2) + ' KB';
        else if (bytes < 1024 * 1024 * 1024) return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
        else return (bytes / (1024 * 1024 * 1024)).toFixed(6) + ' GB';
    }

    const submitSeperation = async () => {
        setStatus("");

        if(fileTarget.length === 0) {
            // setStatus("Please input your audio Target :))")

            const link = document.createElement("a");
            link.href = "#errorTarget";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setErrorTarget(true);
            setTimeout(() => {
                setErrorTarget(false)
            }, 4000);
        } else if(exampleTarget){
            setStatus("Done")
            setAnswer([{speaker: speakerA}, {speaker: speakerB}])
        } else{
            setStatus("File Processed")
            setSpamClick(true)
            const formdata = new FormData();
            formdata.append("userId", userId);
            formdata.append("file", fileTarget[0]);
            formdata.append("numSpeakers", "2");
    
            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow"
            };
    
            fetch(`${process.env.REACT_APP_BE}voice-separation/infer`, requestOptions)
                .then((response) => {
                    if(response.ok) return response.json()
                    else{
                        alert(`Oops Sorry, server is busy... please Reload :) `);
                        setStatus("Done");
                        setSpamClick(false);
                    }
                })
                .then((result) => {
                    // console.log(result); 
                    if(result !== undefined){
                        setStatus(result.status);
                    }
                    
                })
                .catch((error) => {
                    // console.error(error)
                    alert(`Oops Sorry, server is busy... please Reload :) `);
                    setStatus("Done");
                    setSpamClick(false);
                });
        }
    }

    useEffect(() => {
        // console.log(lastJsonMessage)

        if(lastJsonMessage !== null && userId === null){
            setUserId(lastJsonMessage.userId)
        }

        if(status === "File Processed"){
            setStatus("Done")
            setAnswer(lastJsonMessage.result)
            setSpamClick(false)
        }

    // eslint-disable-next-line 
    },[lastJsonMessage])

    return(
        <>
            <Topbar/>

            <section className="capability">

                <div className="breadcrumbDemo">
                    <Link to={"/"}>Home</Link>
                    <img src={arrow} alt="arrow"></img>
                    <Link to={"/demo"}>Demo</Link>
                    <img src={arrow} alt="arrow"></img>
                    <p style={{color: '#37C871'}}>Voice Separation</p>
                </div>

                <div className="contentCapability" id="errorTarget">
                    <p className="titleCapability">See How Voice Separation Transforms Businesses</p>
                    <div className="kotakCapability"></div>
                    <p className="textCapability">Experience the power of our Voice Separation technology through practical, real-world applications. Our demo showcases how this advanced feature can revolutionize various aspects of your business operations.</p>
                </div>

                <div className="mainCapability">

                    <div className="inputCapability">
                        <div className={errorTarget ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"}>                    
                            <label>Media Upload</label>
                            

                            <Dropzone onDrop= {acceptedFiles => {
                                setFileTarget( acceptedFiles.map(file => Object.assign( file, {preview: URL.createObjectURL(file)})) )
                            }} accept={{'audio/mpeg': [], 'audio/wav': []}} maxSize={2000000}>
                                {({getRootProps, getInputProps}) => (
                                    <section onClick={() => {setFileTarget([]); setExampleTarget(false)}}>
                                        <div className="uploadCapability" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <img src={upload} alt="up"/>
                                            <p>Drag your file(s) to start uploading</p>
                                            <div className="orUpload">
                                                <div className="garisPutih"></div>
                                                <p>OR</p>
                                                <div className="garisPutih"></div>
                                            </div>
                                            <button className="buttonGreenFill">Browser file</button>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>

                            <div className="upCapability">
                                <p className="informationUploadCapability">Supported formats: MP3, WAV</p>
                                <p className="informationUploadCapability">Maximum size: 2MB</p>
                            </div>

                            {fileTarget.map((item, index) => (
                                <div key={index} className="itemUploadCapability">
                                    <div className="itemInfoCapability">
                                        <div className="itemDetailInfoCapability">
                                            <img src={mp3} alt="up"></img>
                                            <div className="itemKeteranganCapability">
                                                <p className="itemNameCapability">{item.name}</p>
                                                <p className="itemSizeCapability">{formatBytes(item.size)}</p>
                                            </div>
                                        </div>
                                        <img src={x} onClick={() => {setFileTarget([]); setExampleTarget(false)}} style={{cursor: 'pointer'}} alt="up"/>
                                    </div>
                                    <audio src={item.preview} controls/>
                                </div>
                            ))}

                            <p className="noMargin"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorTarget ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    Please input your audio Target :))
                            </p>
                        </div>
                        <div className="textAreaCapability">   
                            <label>Copy Example</label>
                            <audio src={separation} controls/>
                            <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => {setFileTarget([{preview: separation, name: 'ExampleTarget.mp3', size: 0}]); setExampleTarget(true)}}>Copy Example</button>
                        </div>
                    </div>

                    <div className="submitCapability">
                        <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => submitSeperation()} disabled={spamClick}>Submit</button>
                        {spamClick === true && (
                            <img src={loading} width={60} alt="up"></img>
                        )}
                    </div>

                    <div>
                        <div className="textAreaCapability">                    
                            <label>Result</label>
                            <div className="resultCapability">
                                {status === "Done" ? (
                                    <>
                                    {answer.map((item, index) => (
                                        <audio src={item.speaker} controls/>
                                    ))}
                                    </>
                                ) : (
                                    <>
                                    {status === "File Processed" ? (
                                        <img src={loading} style={{width: '100%'}} alt="up"></img>
                                    ) : (
                                        <>
                                            <p>{status}</p>
                                        </>
                                    )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                </div>



            </section>

            <Footer/>
        </>
    )
}

export default VoiceSeparation